<template>
  <div>
    <v-dialog v-model="dialogs.pumpingRequest" max-width="600">
      <pumping-request
        @close="dialogs.pumpingRequest = false"
      ></pumping-request>
    </v-dialog>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" sm="12">
          <v-select
            :items="SEPTIC_OPTIONS.component_types"
            outlined
            v-model="formData.component_type"
            label="Component Type"
            :rules="rules.requiredRule"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex">
            <v-select
              outlined
              label="Installed by"
              :items="SEPTIC_OPTIONS.installedBy"
              v-model="formData.installed.by"
            ></v-select>
            <v-checkbox
              class="checkbox-notsure"
              v-model="formData.installed.by_dont_know"
              label="Not sure"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex">
            <v-text-field
              outlined
              label="Year installed"
              @input="handleYear('installed')"
              type="number"
              v-model="formData.installed.year"
            ></v-text-field>
            <v-checkbox
              class="checkbox-notsure"
              v-model="formData.installed.year_dont_know"
              label="Not sure"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex">
            <v-text-field
              outlined
              label="Pumped by"
              v-model="formData.pumped.by"
            ></v-text-field>
            <v-checkbox
              class="checkbox-notsure"
              v-model="formData.pumped.by_dont_know"
              label="Not sure"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="d-flex">
            <v-text-field
              outlined
              label="Year last pumped"
              type="number"
              @input="handleYear('pumped')"
              v-model="formData.pumped.year"
            ></v-text-field>
            <v-checkbox
              class="checkbox-notsure"
              label="Not sure"
              v-model="formData.pumped.year_dont_know"
            ></v-checkbox>
          </div>
        </v-col>
      </v-row>

      <v-textarea
        outlined
        rows="2"
        auto-grow
        label="Cleanout location description"
        v-model="formData.cleanout_location_description"
      ></v-textarea>
      <div style="margin-top: -2rem">
        <v-checkbox
          v-model="formData.cleanout_location.exists"
          label="I have a cleanout location sketch to archive"
        ></v-checkbox>
        <file-upload
          v-if="formData.cleanout_location.exists"
          :error.sync="files.errors.cleanout_location"
          :file.sync="files.cleanout_location"
          :report="formData.cleanout_location.sketch"
        ></file-upload>
      </div>
      <input type="submit" hidden />
      <div v-if="missing.show" class="my-2 text-center">
        <v-alert type="info">{{ missing.message }}</v-alert>
        <span v-if="healthDepartment">
          <v-btn color="info" target="_blank" :href="healthDepartment"
            >Health Department Contacts<v-icon>mdi-contacts</v-icon></v-btn
          >
        </span>
        <v-btn
          color="success"
          target="_blank"
          class="ml-2"
          @click="dialogs.pumpingRequest = true"
          >Request Pumping Quote <v-icon>mdi-phone</v-icon></v-btn
        >

        <v-divider class="mt-2"></v-divider>
      </div>
      <div class="text-center">
        <v-btn :loading="loading" color="primary" @click="submit"
          ><slot name="submit"
            >{{ submitText }} <v-icon>mdi-content-save</v-icon></slot
          ></v-btn
        >
        <slot name="cancel">
          <span @click="$emit('cancel')">
            <cancel-button></cancel-button>
          </span>
        </slot>
      </div>
    </v-form>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
import utils from '@/mixins/utils'
import septic from '@/mixins/septic'
import address from '@/mixins/address'
import parcel from '@/mixins/parcel'
import audio from '@/mixins/audio'
export default {
  mixins: [rules, utils, septic, address, parcel, audio],
  props: ['data'],
  components: {
    FileUpload: () => import('@/components/FileUpload'),
    PumpingRequest: () => import('@/components/Septic/PumpingRequest')
  },
  data: () => ({
    defaultFormData: {
      installed: {},
      pumped: {},
      cleanout_location: { sketch: {} }
    },
    formData: {},
    valid: true,
    files: {
      cleanout_location: null,
      description: { cleanout_location: '' },
      errors: { cleanout_location: false }
    },
    loading: false,
    submitText: 'Save',
    missing: {
      complete: false,
      show: false,
      message:
        'Missing information may be available at the Town Health Department. '
    },
    missingList: [],
    dialogs: {
      pumpingRequest: false
    },
    healthDepartment: null
  }),
  computed: {
    pumpingQuoteRequest() {
      let href = ''
      return href
    }
  },
  beforeMount() {
    this.formData = this.copyObject(this.defaultFormData)
  },
  mounted() {
    if (this.data) {
      let d = { ...this.data }
      // if (!("cleanout_location" in d)) {
      //   d = { ...d, cleanout_location: {} };
      // }
      // if(!("installed" in d)){

      // }
      this.formData = d
    }
    this.checkHealthDepartment()
  },
  methods: {
    async uploadDoc() {
      if (this.files.cleanout_location) {
        let doc = await this.uploadFile(this.files.cleanout_location, {
          description: this.files.description.cleanout_location,
          feature: 'septic',
          parcelID: this.$route.params.id || ''
        })
        this.formData.cleanout_location.sketch = {
          ...doc
        }
        this.files.cleanout_location = null
      }
    },
    checkMissingList() {
      this.missingList = []
      let fields = [
        'component_type',
        'year_installed',
        'installed_by',
        'year_last_pumped',
        'cleanout_location_description'
      ]
      if (!this.missing.complete) {
        let emptyField = false
        for (let f of fields) {
          if (!this.formData[f]) {
            this.missingList.push(f)
            emptyField = true
            this.valid = false
          }
        }
        this.missing.complete = true
        if (emptyField) {
          this.missing.show = true
        }
      } else {
        this.valid = true
      }
    },
    validate() {
      if (!this.data) {
        this.checkMissingList()
      }
      this.$refs.form.validate()
    },
    async submit() {
      this.validate()
      if (this.valid) {
        this.loading = true
        await this.uploadDoc()
        this.$emit('submit', this.formData)
        this.playSwordSwing()
        this.loading = false

        this.updateMetrics({ feature: 'septic_information', action: 'update' })
      }
    },
    async checkHealthDepartment() {
      await this.getParcelDetail(this.$route.params.id, true)
      let url = await this.getHealthDepartment(
        this.$store.getters.latestParcel.state,
        this.$store.getters.latestParcel.townCity
      )
      this.healthDepartment = url
    },
    checkYear(year) {
      year = Number(year)
      if (year >= 1900 && year <= new Date().getFullYear()) {
        return true
      } else {
        return false
      }
    },
    handleYear(key) {
      if (this.formData[key].year_dont_know) {
        if (this.checkYear(this.formData[key].year)) {
          this.formData[key].year_dont_know = false
        }
      }
    }
  }
}
</script>

<style scoped></style>
